// // Polyfills
// import 'core-js/fn/array/includes';
// import 'core-js/fn/promise/finally';
// import 'es6-object-assign/auto';
// import 'intl'; // For Safari 9

// import React from 'react';
// import ReactDOM from 'react-dom';

// import BrowserModalComponent from '../components/browser-modal/browser-modal.jsx';
// import AppStateHOC from '../lib/app-state-hoc.jsx';
// import supportedBrowser from '../lib/supported-browser';
// import './index.css';

// const appTarget = document.createElement('div');
// // appTarget.className = styles.app;
// document.body.appendChild(appTarget);

// if (supportedBrowser()) {
//     // require needed here to avoid importing unsupported browser-crashing code
//     // at the top level
//     require('./render-gui.jsx').default(appTarget);

// } else {
//     BrowserModalComponent.setAppElement(appTarget);
//     const WrappedBrowserModalComponent = AppStateHOC(BrowserModalComponent, true /* localesOnly */);
//     const handleBack = () => {};
//     // eslint-disable-next-line react/jsx-no-bind
//     ReactDOM.render(<WrappedBrowserModalComponent onBack={handleBack} />, appTarget);
// }

import 'core-js/fn/array/includes';
import 'core-js/fn/promise/finally';
import 'es6-object-assign/auto';
import 'intl'; // For Safari 9

import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import BrowserModalComponent from '../components/browser-modal/browser-modal.jsx';
import AppStateHOC from '../lib/app-state-hoc.jsx';
import supportedBrowser from '../lib/supported-browser';
import HomePage from './Homepage.jsx';
import './index.css';

const RenderGUIPage = () => {
    const appTarget = document.createElement('div');
    document.body.appendChild(appTarget);

    if (supportedBrowser()) {
        // require needed here to avoid importing unsupported browser-crashing code
        // at the top level
        require('./render-gui.jsx').default(appTarget);
        return null; // Render logic handled by render-gui.jsx
    } else {
        BrowserModalComponent.setAppElement(appTarget);
        const WrappedBrowserModalComponent = AppStateHOC(BrowserModalComponent, true /* localesOnly */);
        const handleBack = () => {};
        return <WrappedBrowserModalComponent onBack={handleBack} />;
    }
};

const App = () => (
    <Router>
        <Routes>
            <Route path="/" element={<RenderGUIPage/>} />
            {/* <Route path="/render-gui" element={<RenderGUIPage />} /> */}
        </Routes>
    </Router>
);

ReactDOM.render(<App />, document.getElementById('root'));
